@import 'scss/core/variables';
@import 'scss/core/mixins';

.p-editor-container {
    & .p-editor-content.ql-snow {
        border-bottom: transparent;
    }

    & .p-editor-content .ql-editor {
    }
}
