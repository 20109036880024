@import 'scss/core/variables';
@import 'scss/core/mixins';

section._tabbed-navigation {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    gap: 1rem;
}

section._tabbed-navigation-nav {
    //height: toRem(40);
}
section._tabbed-navigation-content {
    flex: 1;
    padding-top: toRem(20);
}

.epx-tabs-menu.p-tabmenu {
    & .p-tabmenu-nav,
    & .p-tabview-nav {
        background: transparent;
        border: toRem(1) solid rgba(clr(grey, 600), 0.3);
        border-radius: toRem(8);
        overflow: hidden;
        width: max-content;
    }

    & .p-tabmenu-ink-bar {
        display: none;
    }

    li.p-highlight .p-menuitem-link:focus {
        background-color: transparent;
    }

    .p-tabmenuitem {
        color: clr(primary, main);
        padding: 0;

        &.p-highlight {
            & > a.p-menuitem-link {
                background-color: $palette-primary-main !important;
                color: white;
            }

            &:hover:not(:disabled) {
                & > a.p-menuitem-link {
                    background-color: $palette-primary-main !important;
                    color: white;
                }
            }
        }

        a.p-menuitem-link {
            background: white;
            border: none;
            color: inherit;
            font-weight: $font-weight-semi-bold;
            font-size: toRem(14);
            line-height: toRem(20);
            padding: toRem(10) toRem(20);
            border-radius: toRem(8);

            .p-menuitem-icon {
                font-size: 2.5rem;
            }

            .epx-tab-item-label {
            }
            .epx-tab-item-icon {
                margin-right: toRem(5);
            }

            .tab-item {
                line-height: 2.5rem;
                display: inline-flex;
            }
            &.p-ripple {
                .p-ink {
                    background: rgba($palette-primary-main, 0.2);
                }
            }

            &.p-menuitem-link-active:not(:disabled) {
                color: white !important;
                background-color: $palette-primary-main;
                &:hover:not(:disabled) {
                    background-color: $palette-primary-main !important;
                }
            }

            &:hover:not(:disabled) {
                background-color: rgba(clr(grey, 600), 0.1) !important;
            }
        }
    }
}
