@import 'variables';
@import 'mixins';

app-dashboard,
app-user-profile-page,
page-customer-details,
page-employee-details,
page-benefit-details,
app-ui-demo,
app-module-router-layout {
    flex: 1;
}

._module-content {
    padding: toRem(30);
}
