@import 'scss/core/variables';
@import 'scss/core/mixins';

.descriptions-header {
    padding: toRem(12) toRem(24);
    background: $palette-primary-main;
    color: white;
    border-radius: toRem(4);
    font-weight: $font-weight-medium;
    font-size: toRem(14);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-of-type {
        margin-top: toRem(20);
    }
}

.description-element {
    font-size: toRem(14) !important;
    display: flex;
    justify-content: space-between;

    & .lang-indicator {
        & span {
            line-height: toRem(32);
            &:not(:last-of-type):after {
                content: '|';
                margin: 0 toRem(5);
            }

            &.lang-missing {
                color: clr(error, main);
            }

            &.lang-set {
                color: clr(success, main);
            }
        }
    }
}
