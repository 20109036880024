@import 'scss/core/variables';
@import 'scss/core/mixins';

label.p-checkbox-label {
    font-size: toRem(12);
    font-style: normal;
    font-weight: $font-weight-medium;
    margin-bottom: 0;
    line-height: toRem(18);
    margin-left: toRem(12);

    & span {
    }
}

.epx-checkbox {
    &.p-checkbox {
        & .p-checkbox-box {
            border: 1px solid clr(grey, 500);
        }

        & .p-checkbox-box.p-highlight {
            border-color: clr(grey, 500);
            color: clr(success, main);
            background: white;

            & .p-checkbox-icon {
                color: clr(success, main);
                top: -2px;
            }

            &:hover {
                background: transparent;
                border-color: clr(grey, 500);
                color: clr(success, main);
            }

            &:focus {
                background: transparent;
                border-color: clr(grey, 500);
                color: clr(success, main);
            }
        }
    }
}

.epx-checkbox.p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled).p-checkbox-focused {
    box-shadow: none;
}
