@import 'scss/core/variables';
@import 'scss/core/mixins';

.epx-accordion-tab.p-accordion-tab {
    box-shadow: none;
    border-bottom: 1px solid clr(grey, 500);
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    transition: border ease-in-out 0.3s;

    & .p-accordion-content {
        padding: toRem(20) toRem(25);
    }

    & .p-accordion-header {
        & > .p-accordion-header-link {
            padding: toRem(16) toRem(10);
            font-size: toRem(16);
            font-weight: $font-weight-bold;
        }

        &:not(.p-highlight):not(.p-disabled):hover {
            & .p-accordion-header-link {
                background: clr(grey, 300);
                border-color: transparent;
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }
}

.epx-accordion-tab.p-accordion-tab.p-accordion-tab-active {
    box-shadow: none;
    border-bottom: 1px solid clr(grey, 500);
    border-left: 1px solid clr(grey, 500);
    border-right: 1px solid clr(grey, 500);
    transition: border ease-in-out 0.3s;

    & .p-accordion-content {
        padding: toRem(20) toRem(25);
    }

    & .p-accordion-header {
        & > .p-accordion-header-link {
            padding: toRem(16) toRem(10);
            font-size: toRem(16);
            font-weight: $font-weight-bold;
            background: clr(grey, 300);
        }
    }

    &:hover {
        & .p-accordion-header {
            & > .p-accordion-header-link {
                background: clr(grey, 300);
            }
        }
    }
}
