@import 'scss/core/mixins';

._epx-paper-card {
    padding: 2.5rem;

    &._no-padding {
        padding: 0;
    }

    &._background {
        background: white;
        @include box-shadow(0, 2px, 4px, 0, clr(grey, 600));
        @include border-radius(4);
    }
}
