@import 'scss/core/mixins';
form.stretched {
    display: flex;
    justify-content: stretch;
    flex-flow: column;
    height: 100%;

    & > .stretched-form-row:first-of-type {
        flex: 1;
    }

    & > .stretched-form-row:last-of-type {
        height: toRem(60);
    }
}
