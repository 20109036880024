@import 'scss/core/variables';
@import 'scss/core/mixins';

.p-calendar {
    width: 100%;
}

/*
** General Input Float Label Styles
*/

input[type='text']:not(.p-chips-input-token input),
input[type='password'],
input[type='email'],
input[type='number'],
input[type='search'],
input.p-inputnumber-input,
.epx-input {
    color: $palette-primary-main;
    font-family: $font-family-primary;
    background-color: white;
    font-weight: $font-weight-regular;
    font-style: normal;
    font-size: toRem(13);
    line-height: toRem(21);
    width: 100%;
    border-color: clr(input, border);
    @include border-radius(4);
    transition: all ease-in-out 0.3s;
    padding-top: toRem(13.5);
    padding-bottom: toRem(13.5);

    &:enabled:hover:not(.ng-dirty.ng-invalid) {
        transition: all ease-in-out 0.3s;
    }

    &:enabled:focus:not(.ng-dirty.ng-invalid) {
        border-width: toRem(1.5);
        border-color: $palette-primary-main;
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }

    &:enabled:focus.ng-dirty.ng-invalid {
        box-shadow: none;
    }

    &:enabled.ng-dirty.ng-invalid {
        border-color: clr(error, main);
    }

    &:disabled {
        border-color: clr(grey, 300);
        color: clr(grey, 800);
    }

    &.epx-input-variant {
        padding-top: toRem(9.5);
        padding-bottom: toRem(9.5);
    }
}

.p-password,
.p-password-input,
p-inputNumber,
.p-inputnumber {
    width: 100%;
}

.epx-input-number.p-inputnumber {
    &.p-inputnumber-buttons-stacked {
        & input {
            @include borderRadiusSingle($topRight: 0, $bottomRight: 0);
        }
    }
    & .p-inputnumber-button {
        background-color: $palette-secondary-main;
        color: white;
        transition: all ease-in-out 0.3s;

        &:enabled:hover {
            background-color: $palette-secondary-dark;
            color: #ffffff;
            transition: all ease-in-out 0.3s;
        }

        &:enabled:focus {
            background-color: $palette-secondary-dark;
            box-shadow: none;
            color: #ffffff;
            transition: all ease-in-out 0.3s;
        }

        &:enabled:active {
            background-color: $palette-secondary-main;
            color: white;
        }

        &:disabled {
            background-color: $palette-secondary-light !important;
            color: white;
        }
    }
}

.epx-chips-input {
    display: block;
    width: 100%;

    & .p-inputtext {
        border-color: clr(input, border);
        @include border-radius(4);
        transition: all ease-in-out 0.3s;
    }

    & .p-chips-multiple-container {
        width: 100%;
        padding: toRem(5) toRem(18);

        & .p-chips-input-token input {
            font-family: $font-family-primary;
        }
    }

    & .p-chips-multiple-container .p-chips-token {
        color: clr(primary, main);
        background-color: clr(primary, light);
        font-size: toRem(13);
        padding: toRem(5);
        & > .p-chips-token-label {
            font-family: $font-family-primary;
        }
        & > .p-chips-token-icon {
        }
    }

    & .p-chips-multiple-container:not(.p-disabled).p-focus {
        box-shadow: none;
        border-color: clr(input, border);
    }

    & .p-chips-input-token input {
        padding-left: toRem(20);
    }
}

.epx-file-upload {
    &.p-fileupload {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: stretch;
        position: relative;

        & .p-fileupload-files {
            display: none;
        }

        & .p-progressbar {
            display: none;
        }

        & .p-fileupload-buttonbar {
            position: absolute;
            width: 100%;
            display: none;
            border: transparent;
            justify-content: center;
            align-items: center;
            bottom: 25%;
            z-index: 100;
        }

        & .p-fileupload-content {
            flex: 1;
            padding: toRem(25);
            border: none;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.epx-textarea.p-inputtextarea {
    box-sizing: border-box !important;
    width: 100%;
    border-radius: toRem(4);
    min-height: unset !important;
    height: unset !important;
    line-height: toRem(20);
    scrollbar-gutter: stable both-edges;
    padding: 0 0.5rem;
    resize: none;
    border-width: toRem(10) 0 toRem(20) 0;
    border-style: solid;
    border-color: transparent !important;
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    box-shadow: 0 0 0 1px clr(grey, 500);

    &:enabled:focus:not(:disabled).ng-invalid.ng-dirty {
        box-shadow: 0 0 0 1px clr(error, main);
        border-color: transparent !important;
    }

    &.ng-dirty.ng-invalid {
        box-shadow: 0 0 0 1px clr(error, main);
        border-color: transparent !important;
    }

    &:hover:not(:disabled) {
        overflow-y: auto;
        transition: all ease-in-out 0.3s;
    }
    &:hover,
    &:focus:not(:disabled) {
        border-color: transparent !important;
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 1px $palette-primary-main;
    }
    @include scrollbars();
}

.epx-input-field > .p-inputgroup {
    & .p-inputtext {
        border-right: none;
        @include borderRadiusSingle($topRight: 0, $bottomRight: 0);
    }

    & .p-inputgroup-addon {
        border-color: clr(input, border);
    }
}

.epx-input-field.epx-char-counter {
    &::after,
    &::before {
        color: $palette-primary-main;
        font-size: toRem(10);
        position: absolute;
        bottom: 0.35rem;
    }

    &::after {
        content: attr(max-count);
        width: toRem(40);
        right: 0;
    }
    &::before {
        content: attr(counter-value);
        right: toRem(45);
    }
}

.epx-calendar.p-calendar {
    border-color: clr(input, border);

    &.p-calendar-w-btn.p-focus {
        border-color: $palette-primary-main;
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }
}

.p-datepicker table td > span {
    height: toRem(32);
    width: toRem(32);
    line-height: toRem(32);
}
.p-datepicker table td > span.p-highlight {
    color: $palette-primary-main;
    background-color: rgba($palette-secondary-light, 0.8);
}
