@import 'scss/core/variables';
@import 'scss/core/mixins';

p-dropdown {
    width: 100%;
}

button.p-autocomplete-dropdown {
    background-color: clr(primary, main);
    color: white;
}

.epx-dropdown-panel {
    font-weight: $font-weight-medium;

    &.p-dropdown-panel {
        & > .p-dropdown-items-wrapper {
            background-color: white;
            max-height: calc(52.8px * 4) !important;
            @include scrollbars();
        }

        & .p-dropdown-items .p-dropdown-item {
            color: $palette-primary-main;
            padding: toRem(12) toRem(16);
            font-family: $font-family-primary;
            font-weight: $font-weight-regular;
            font-style: normal;
            font-size: toRem(13);
            line-height: toRem(16);

            &.p-highlight {
                color: $palette-primary-main !important;
                background: clr(primary, light) !important;
            }

            & .p-ink {
                background-color: clr(grey, 200);
            }
        }
    }
}

.epx-dropdown {
    width: 100%;

    &.p-dropdown {
        background-color: white;
        @include border-radius(0.5rem);
        border: 1px solid clr(input, border);
        height: toRem(42);
        width: 100%;

        & .p-dropdown-trigger {
            height: 100%;
        }

        & .p-dropdown-filter-container {
            input {
                height: toRem(40);
            }
        }

        & .p-dropdown-label {
            color: $palette-primary-main;
            font-family: $font-family-primary;
            font-weight: $font-weight-regular;
            font-style: normal;
            font-size: toRem(13);
            line-height: toRem(16);
            padding: toRem(12) toRem(16);
            width: 100%;
            height: 100%;
        }

        &:not(.p-disabled):hover {
            border-color: clr(input, border-focus);

            &.p-focus {
                border-color: $palette-primary-main;
            }
        }

        &:not(.p-disabled).p-focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
            border-color: $palette-primary-main;

            & .p-dropdown-trigger {
                color: $palette-primary-main;
            }
        }
    }
}
