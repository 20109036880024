@import 'scss/core/mixins';
@import 'scss/core/variables';

.epx-menu.p-menu {
    width: unset;
    min-width: toRem(200);

    & .p-menu-list {
        & .p-menuitem {
            & > .p-menuitem-link {
                padding: 1rem 2rem;

                & .p-menuitem-text {
                    font-size: toRem(12);
                    padding-left: toRem(10);
                }
            }
        }
    }
}

.epx-filter {
    display: flex;
    flex-flow: column;
    justify-content: stretch;

    & > .epx-filter-header {
        height: toRem(30);
        border-bottom: toRem(2) solid $border-color;
    }
    & > .epx-filter-content {
        flex: 1;
        margin-top: toRem(5);
    }
}

.epx-filter-box {
    padding: toRem(5) 0;
    & .filters {
        padding: toRem(12) 0 0 0;
    }
}
