@import 'scss/core/variables';
@import 'scss/core/mixins';

label {
    font-size: toRem(14);
    line-height: toRem(25);
    font-weight: $font-weight-bold;
    color: inherit;
    display: flex;
    justify-content: space-between;

    & span:not(:first-of-type) {
        font-size: toRem(10);
        color: clr(grey, 600);
    }
}

.epx-form-field {
    position: relative;
    margin-bottom: toRem(16);
    display: flex;
    flex-flow: column;
    color: $palette-primary-dark;

    & label {
        margin-bottom: toRem(2);
    }

    & > .epx-input-field {
        position: relative;
    }

    /* &.epx-field-invalid {
        color: clr(error, main);
    }*/

    & .epx-input-validations {
        position: absolute;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: toRem(11);
        line-height: toRem(13);
        color: clr(error, main);
        min-height: toRem(12);
        padding: 0 toRem(3);
        bottom: -30%;
        & small {
            color: inherit;
        }
    }
}

.p-input-icon-left > .epx-input-addition,
.p-input-icon-right > .epx-input-addition {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    height: 100%;
    top: 0;
    gap: toRem(5);

    & label {
        font-weight: $font-weight-regular;
        margin: 0;
        font-size: toRem(12);
    }

    & .epx-c-icon-btn {
        &:hover:not(:disabled):not(:focus) {
            //background-color: transparent !important;
        }

        &:focus:not(:disabled) {
            //background-color: transparent !important;
        }
    }
}

.p-input-icon-left > span.epx-input-addition:first-of-type {
    width: 3rem;
    left: 0;
}

.p-input-icon-right > span.epx-input-addition:last-of-type {
    width: unset;
    right: toRem(10);
}

.epx-form-field.epx-form-field-row {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    align-items: center;
    margin-bottom: 0;

    & label {
        margin-bottom: 0;
    }
}
