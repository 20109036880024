@import 'scss/core/variables';
@import 'scss/core/mixins';

.epx-tag.p-tag {
    background-color: clr(primary, light);
    color: clr(grey, 800);
    padding: toRem(4) toRem(16);

    &.epx-tag-primary {
        background-color: clr(primary, main);
        color: white;
    }
    &.epx-tag-secondary {
        background-color: clr(secondary, main);
    }
    &.epx-tag-secondary-light {
        background-color: clr(secondary, light);
    }
    &.epx-tag-info {
        background-color: clr(info, main);
        color: white;
    }
    &.epx-tag-error {
        background-color: clr(error, main);
    }
    &.epx-tag-error-light {
        background-color: clr(error, light);
    }
    &.epx-tag-warn {
        background-color: clr(warn, main);
    }
    &.epx-tag-warn-light {
        background-color: clr(warn, light);
    }

    & .epx-tag-label {
        font-weight: $font-weight-medium;
        font-size: toRem(10);
    }
}
