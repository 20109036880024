@import 'scss/core/variables';
@import 'scss/core/mixins';

$headerButton: toRem(40);

.epx-confirm.p-confirm-popup {
    margin-top: toRem(25);

    &:before {
        display: none;
    }

    &:after {
        display: none;
    }

    & .p-confirm-popup-footer {
        & .p-button {
            @include button-base();
            @include button-secondary();
        }
        & .p-button:last-child {
            @include button-primary();
        }
    }
}

.epx-confirm.p-confirm-dialog {
    & .p-dialog {
        min-width: 30vw;
        border-radius: toRem(4);
        overflow: hidden;
        background-color: white;
        box-shadow: $big-box-shadow;
        max-width: calc(100% - 6rem);
        max-height: unset;
    }

    & .p-dialog-header {
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: toRem(1) solid $palette-primary-light;
        padding: toRem(10) toRem(20);

        & .p-dialog-title {
            margin: 0 toRem(10) 0 0;
            font-size: toRem(16);
            font-weight: $font-weight-bold;
            padding: 0;
            line-height: toRem(25);
            letter-spacing: 0.00938em;
            color: inherit;
        }
    }

    & .p-dialog-content {
        padding: 0;
        & .p-confirm-dialog-message {
            padding: toRem(10) toRem(20);
            margin: 0;
            color: $palette-primary-main;
        }
    }
}

.epx-dialog {
    &.p-dialog {
        min-width: 30vw;
        border-radius: toRem(4);
        overflow: hidden;
        background-color: white;
        box-shadow: $big-box-shadow;
        max-width: calc(100% - 6rem);
        max-height: unset;
    }

    & .p-dialog-header {
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: toRem(17) toRem(35) toRem(17) toRem(50);
        border-bottom: toRem(1) solid $palette-primary-light;

        .header-content {
            width: calc(100% - $headerButton);
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
        }

        & .header-component {
        }

        & h3 {
            margin: 0;
            font-size: toRem(16);
            font-weight: $font-weight-bold;
            padding: 0;
            line-height: toRem(25);
            color: inherit;

            & span {
                line-height: inherit;
                font-size: inherit;
                font-weight: inherit;
            }
        }
    }

    &.without-header {
        & .p-dialog-header {
            border-color: transparent;
        }
    }

    & .p-dialog-content {
        justify-content: center;
        padding: 0;
        color: $palette-primary-main;
        scrollbar-gutter: stable both-edges;
        overflow: hidden;
        @include scrollbars();
        @include scrollContainerHover();

        & .epx-dialog-content {
            height: 100%;
            padding: toRem(30) toRem(50) toRem(30) toRem(50);
        }
    }

    & .p-dialog-footer {
        border-radius: 0;
        justify-content: center;
        text-align: center;
        padding: 0 toRem(50) toRem(40) toRem(50);
    }

    .p-dialog-mask.p-component-overlay {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

.epx-overlay-panel {
    &.p-overlaypanel {
        border-radius: toRem(4);
        overflow: hidden;
        box-shadow: $small-box-shadow;
        z-index: 9999;

        &:after,
        &:before {
            display: none;
        }

        & .epx-overlay-panel-content {
            padding: toRem(20);
        }
    }

    & .p-overlaypanel-content {
        padding: 0;
        color: $palette-primary-main;
    }
}
