@import '../core/variables';
@import '../core/mixins';

ul.epx-list {
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
        height: toRem(60);
        display: flex;
        align-items: center;
        color: $palette-primary-main;
        font-size: 1.6rem;
        @include borderRadiusPrimary();

        &:not(:last-of-type) {
            border-bottom: 2px solid $border-color;
        }

        & > .content {
            padding: toRem(10);
            height: 100%;
            width: 100%;
        }
    }

    &.epx-list-hover {
        & > li {
            &:hover {
                cursor: pointer;
                background-color: clr(primary, light);
            }
        }
    }

    &.epx-list-scrollable {
        overflow: hidden;
        scrollbar-gutter: stable both-edges;
        @include scrollbars();
        @include scrollContainerHover();

        & > li {
            padding-right: 0.5rem;
        }
    }
}
