@import 'variables';
@import 'mixins';

:root {
    --border-radius: toRem(4);
}

html {
    font-size: 1rem;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-family-primary !important;
    font-weight: $font-weight-regular;
    font-size: $font-size-body;
    font-style: normal;
    line-height: 1.3em;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: $palette-primary-main;
    scrollbar-gutter: stable both-edges;
    @include scrollbars($palette-primary-main);
}

html,
body {
    background: #f2f1f1;
    height: 100%;
}

a {
    color: $palette-secondary-main;
    line-height: 0.938rem;
    font-size: 0.75rem;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }

    &:active {
        background-color: white;
    }
}

.p-component {
    font-family: $font-family-primary;
    color: $palette-primary-main;
    font-weight: $font-weight-regular;
}

* {
    box-sizing: border-box;
}

p {
    margin: 0.5em auto;
}

:focus {
    outline: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
