@import 'scss/core/variables';
@import 'scss/core/mixins';

.epx-message {
    &.p-messages {
        & .p-message {
            border-radius: 0;
        }
        & .p-message.p-message-info {
            background: clr(info, main);

            & .p-message-wrapper {
                color: white;
            }
        }
    }

    &.p-messages .p-message .p-message-wrapper {
        padding: toRem(10);
    }
}

.epx-toast {
    &.p-toast {
        width: unset;
        opacity: 1;
        top: 5rem;

        & .p-toast-icon-close {
            width: toRem(10);
            height: toRem(10);

            & .p-toast-icon-close-icon {
                color: clr(grey, 600) !important;
            }

            &:hover {
                background-color: transparent;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        & .p-toast-message {
            height: 3rem;
            border-radius: toRem(4);
            background: clr(primary, main);
            margin: 0 0 1rem 0;
            position: relative;
            border-style: solid;
            border-color: currentColor;
            border-width: 0 0 0 toRem(8);

            &.p-toast-message-success {
                color: clr(success, main);
            }

            &.p-toast-message-warn {
                color: clr(warn, main);
            }

            &.p-toast-message-error {
                color: clr(error, main);
            }

            &.p-toast-message-info {
                color: clr(info, main);
            }

            & > .p-toast-message-content {
                width: 100%;
                height: 100%;
                padding: 0 1rem;
                display: flex;
                justify-content: stretch;
                align-items: center;
            }

            & section.epx-toast-message {
                flex: 1;
                height: 100%;
                display: flex;
                flex-flow: row;
                justify-content: stretch;
                align-items: center;
                gap: 1rem;

                & > section.epx-toast-icon {
                    color: currentColor;
                    display: flex;

                    & > i {
                        font-size: 1rem;
                        line-height: 1.2;
                    }
                }

                & > section.epx-toast-content {
                    color: currentColor;
                    flex: 1;
                    padding-right: 1rem;
                }

                & .epx-toast-summary {
                    color: currentColor;
                    font-size: toRem(12);
                    line-height: 1.2;
                    text-align: left;
                }

                & .epx-toast-details {
                    color: currentColor;
                    font-size: toRem(14);
                    line-height: 1.2rem;
                }
            }
        }
    }
}
