@import 'scss/core/variables';
@import 'scss/core/mixins';

.epx-color-primary {
    color: clr(primary, main);
}

.epx-color-secondary {
    color: clr(secondary, main) !important;
}

.epx-color-success {
    color: clr(success, main) !important;
}

.epx-color-info {
    color: clr(info, main);
}

.epx-color-warn {
    color: clr(warn, main);
}

.epx-color-danger {
    color: clr(error, main);
}

.epx-color-help {
    color: clr(help, main);
}

.epx-color-subtile {
    color: clr(grey, 700);
}

.epx-color-white {
    color: white;
}

.content-stretched {
    display: flex;
    justify-content: stretch;
    flex-flow: column;

    & .content-row {
        flex: 1;
    }
    & .content-row:last-of-type {
        height: toRem(60);
        flex: unset;
    }
}

span.hint {
    padding: toRem(10) 0;
    color: clr(grey, 700);
    font-size: toRem(12);
    font-weight: $font-weight-regular;
    display: block;

    & > span {
    }
}

div.epx-divider {
    height: toRem(2);
    background-color: $border-color;
    width: 100%;
}
